<template>
  <div class="pbf">
     <BrandTabReviews
        v-if="type == 'brands'"
        :inurl="`/${props.type}/${props.id}/reviews`"
        :brand-id="props.id"
        :sort-options="[{id:'create', name: $t('universal_sort_creation_date')},]"
      />
      <BrandTabReviews
          v-else
          :inurl="`/${props.type}/${props.id}/reviews`"
          :product-id="props.id"
          :sort-options="[{id:'create', name: $t('universal_sort_creation_date')},]"
        />
  </div>
</template>

<script setup>
const { $api, $tagsUtil, $ga } = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})

</script>
<style scoped></style>
